import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-venta-pdf',
  templateUrl: './venta-pdf.component.html',
  styleUrls: ['./venta-pdf.component.scss']
})
export class VentaPdfComponent implements OnInit {

  @Input() ventas: any;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() { }

  ngOnInit(): void {
    if (this.ventas.message === 'Ventas') {
      this.displayedColumns = ['numBoucher', 'cantProd', 'valorTotal', 'fecha', 'tipoPago'];
      this.dataSource.data = this.ventas.content;
    } else {
      if (this.ventas.message === 'DetallePedido') {
        this.displayedColumns = ['codigo', 'nombre', 'descripcion', 'familiaProducto', 'cantidad'];
        this.dataSource.data = this.ventas.content.productos;
      }
      if (this.ventas.message === 'StockVajo') {
        this.displayedColumns = ['codigo', 'nombre', 'descripcion', 'familiaProducto', 'cantidad'];
        this.dataSource.data = this.ventas.content;
      }
      if (this.ventas.message === 'DetalleEnvio') {
        this.displayedColumns = ['email', 'nombre', 'rut', 'telefono', 'direccion'];
        this.dataSource.data = this.ventas.content;
      }
      if (this.ventas.message === 'prodTraspaso') {
        this.displayedColumns = ['codigo', 'nombre', 'descripcion', 'familiaProducto', 'cantidad'];
        this.dataSource.data = this.ventas.content;
      }
      if (this.ventas.message === 'inventario') {
        this.displayedColumns = ['codigo', 'nombre', 'descripcion', 'familiaProducto', 'cantidad'];
        this.dataSource.data = this.ventas.content;
      }
    }
  }

  imprimir() {
    if (this.ventas.content.length > 0) {
      // const DATA = document.getElementById('tablaData');
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont("courier");
      doc.setFontType("italic");
      doc.setFontSize(11);

      var data = 'Codigo \tCantidad     familia producto\tnombre\n';
      data = data + '------------------------------------------------------------------------------------\n';
      var max = 0;
      this.ventas.content.forEach(element => {
        if(element.familiaProducto.length>max){
          max = element.familiaProducto.length;
        }
      });
      var pagina = 29;
      this.ventas.content.forEach((element, idx) => {
        var aux = element.familiaProducto;
        var aux1 = 0;
        var aux2 = 0;
        aux1 = max-element.familiaProducto.length;
        while(aux2<=aux1){
          aux = aux +' ';
          aux2++;
        }
        data = data + element.codigo + '      ' + element.cantidad + '         ' + aux + '  ' + element.nombre + '\n';
        data = data + '-----------------------------------------------------------------------------------\n';
        if(idx===pagina-1){
          doc.text(20, 20, data);
          data='';
        }
        if(idx===pagina){
          pagina = pagina + 30;
          doc.addPage('a4', 'p');
        }
      });
      
      doc.output('dataurlnewwindow');

    }
    // doc.addPage();
    // const options = {
    //   background: 'white',
    //   scale: 3
    // };

    // html2canvas(DATA, options).then((canvas) => {

    //   const img = canvas.toDataURL('image/PNG');

    //   // Add image Canvas to PDF
    //   var bufferX = 15;
    //   var bufferY = 15;
    //   // const imgProps = (doc as any).getImageProperties(img);
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

    //   var heightLeft = pdfHeight;
    //   var pageHeight = 800;
    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     bufferY = heightLeft - pdfHeight;
    //     doc.addPage();
    //     doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //     heightLeft -= pageHeight;
    //   };

    //   return doc;
    // }).then((docResult) => {
    //   docResult.output('dataurlnewwindow');
    // });
  }

  comprobante() {
    const demoId = document.querySelectorAll('.cdk-overlay-pane');
    demoId[0].setAttribute('style', 'top: 0;')
    setTimeout(() => { window.print() }, 800);
    // window.location.reload();
  }

  cerrarV() {
    //PENDIENTE: descargar comprobante
    window.location.reload();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

}
