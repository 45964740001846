<ng-template #showNoEdictPost>
    <app-details-post [post]="data"></app-details-post>
</ng-template>

<h2 *ngIf="data.message && data.message != 'pdf'">{{data.message}}</h2>
<mat-dialog-content class="dialog-content">
    <div *ngIf="data.content; else showNewForm">
        <div *ngIf="data.message === 'Detalle'; else showNoDetails">
            <app-details-post [post]="data"></app-details-post>
        </div>
        <ng-template #showNoDetails>
            <div *ngIf="data.message === 'Bolsa De Compras'; else showNoCarro">
                <app-details-post [post]="data"></app-details-post>
                <!-- <app-carro-venta [post]="data.content"></app-carro-venta> -->
            </div>
            <ng-template #showNoCarro>
                <div *ngIf="data.message; else showNoEdictPost">
                    <div *ngIf="data.message != 'Factura'; else showEditFactura">
                        <div *ngIf="data.message != 'pdf'; else showNoNewFormPdf">
                            <div *ngIf="data.message != 'Carro Cambio'; else CarroCambio">
                                <app-edit-post [dataCompleta]="data"></app-edit-post>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>

    <ng-template #showNewForm>
        <div *ngIf="data.message != 'Factura'; else showNoNewForm">
            <div *ngIf="data.message != 'pdf'; else showNoNewFormPdf">
                <app-new-post [dataCompleta]="data"></app-new-post>
            </div>
        </div>
    </ng-template>
    <ng-template #showNoNewForm>
        <app-ingreso-factura [factura]="data"></app-ingreso-factura>
    </ng-template>

    <!-- PENDIENTE: realizar la edicion de factura -->
    <ng-template #showEditFactura>
        <app-ingreso-factura [factura]="data"></app-ingreso-factura>
    </ng-template>

</mat-dialog-content>

<ng-template #showNoNewFormPdf>
    <app-ingreso-factura [factura]="data"></app-ingreso-factura>
</ng-template>

<ng-template #CarroCambio>
    <app-carro-cambio ></app-carro-cambio>
</ng-template>