<mat-dialog-content class="dialog-content">

    <div *ngIf="data.message != 'Comprobante'; else showComprobante">
        <div *ngIf="data.message != 'DetallePedido'; else showDetallePedido">
            <div *ngIf="data.message != 'StockVajo'; else showStockVajo">
                <div *ngIf="data.message != 'DetalleEnvio'; else showDetalleEnvio">
                    <div *ngIf="data.message != 'prodTraspaso'; else showGiaTraspaso">
                        <div *ngIf="data.message != 'inventario'; else showInventario">
                            <app-venta-pdf [ventas]="data"></app-venta-pdf>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #showComprobante>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>

    <ng-template #showDetallePedido>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>
    <ng-template #showStockVajo>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>
    <ng-template #showDetalleEnvio>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>
    <ng-template #showGiaTraspaso>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>
    <ng-template #showInventario>
        <app-venta-pdf [ventas]="data"></app-venta-pdf>
    </ng-template>
    

</mat-dialog-content>