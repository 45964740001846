<!-- ++++++template del detalle++++++ -->
<!-- <div class="container" *ngIf="post.message =='Detalle'; else loading">
    <div class="card2">
        <div class="image">
            <a>
                <img [src]="post.content.imageproduct" class="card-img-top" />
            </a>
        </div>
    </div>

    <mat-card class="post-card">
        <mat-card-content>
            <mat-card-title>
                {{post.content.nombre}}
            </mat-card-title>
        </mat-card-content>
        <div class="card-inner2">
            <div>
                <h4 class="text-muted">{{post.content.valor}}</h4>
                <small class="text-muted">{{post.content.familiaProducto}}</small>
            </div>
        </div>
    </mat-card>
</div> -->

<div *ngIf="post.message =='Detalle'; else loading" style="width: 800px;">
    <div id="containesI" style="width: 55%; display: inline-block;">
        <div>
            <a>
                <img [src]="post.content.imageproduct" class="card-img-top" />
            </a>
        </div>
    </div>

    <mat-card id="containesT" style="width: 45%; display: inline-block;padding-left: 5%;">
        <div>
            <mat-card-title>
                {{post.content.nombre}}
            </mat-card-title>
        </div>
        <div>
            <div>
                <h4 class="text-muted">{{post.content.valor}}</h4>
                <small class="text-muted">{{post.content.familiaProducto}}</small>
            </div>
        </div>

        <div style="width: 63px; border: 1px solid black; border-radius: 20px; display: inline-block;">
            <div style="width: 20px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;"
                (click)="restarProd()">-</div>
            <div style="width: 20px; padding: 5px; text-align: center;  display: inline-block;">1</div>
            <div style="width: 20px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;"
                (click)="sumarProd()">+</div>
        </div>

        <button style="border-radius: 30px;" class="card-inner header" mat-stroked-button
            (click)="agregarAlCarro()">Agregar a la bolsa</button>

    </mat-card>
</div>


<!-- <ng-template #loading>
    <div class="container-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template> -->

<!-- ++++++template del boucher++++++ -->
<ng-template #loading>
    <div class="ticket" style="top: 0px; left: 0;" *ngIf="total; else carroCompras" id="datBouch">
        <img class="img1" src="assets/img/logo.jpeg" alt="Logotipo">
        <p class="centrado">Victoria
            <br>Gral. Lagos 452
            <!-- <br> PENDIENTE: FORMA DE PAGO Y NUMERO DE VOUCHER -->
            <br>{{date | date:'dd-MM-yyyy HH:mm:ss-SS'}}
            <br>Número de Boleta {{post.voucher}}
            <br>Forma De Pago {{post.tipoPago}}
        </p>
        <table>
            <thead>
                <tr>
                    <th class="cantidad">CANT</th>
                    <th class="producto">DETALLE</th>
                    <th class="precio">$$</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let post of posts">
                    <td class="cantidad">{{post.cantidad}}</td>
                    <td class="producto">{{post.nombre}}</td>
                    <td class="precio">${{post.valor}}</td>
                </tr>

                <tr>
                    <td class="cantidad"></td>
                    <td class="producto">TOTAL</td>
                    <td class="precio">${{total}}</td>
                </tr>
            </tbody>
        </table>
        <p class="centrado">¡GRACIAS POR SU COMPRA!
            <br>isabellavictoria.cl
        </p>
        <button class="oculto-impresion" (click)="imprimir()">Imprimir</button>
    </div>
</ng-template>


<!-- ++++++template de carro de compras++++++ -->

<ng-template #carroCompras>
    <div *ngFor="let prod of posts">
        <div class="card3">
            <img [src]="prod.imageproduct" style="margin: 5px; float: left;">
            <div style="text-align: left; float: left; margin-left: 20px;">
                <div style="margin: 5px; font-weight: 600; font-size: 13px;
                            color: #222;
                            line-height: 1.428;
                            display: block;">
                    <h3>{{prod.nombre}}</h3>
                </div>
                <div style="margin: 5px; display: block;">
                    <h4>$ {{prod.valor}}</h4>
                </div>
                <div style="width: 40px; margin: 5px; border-radius: 10px; border: 1px solid gray; text-align: center;">{{prod.cantidad}}
                </div>
            </div>
        </div>
    </div>
    <div class="card3">
        <div>$ {{post.valorCompra}}</div>
    </div>
    <a *ngIf="post.valorCompra>0" mat-stroked-button routerLink="carro" [mat-dialog-close] = "true" style="text-decoration: none; border-radius: 25px;margin-right: 10px;background-color: #1fb2a6;" class="card-inner header">Realizar pedido</a>
    <a mat-stroked-button routerLink="home" [mat-dialog-close] = "true" style="text-decoration: none; border-radius: 25px;background-color: #1fb2a6;" class="card-inner header">Seguir Comprando</a>
</ng-template>