<div style="width: 90%; height: 60px; background-color: #f6f6f8; font-size: 13px;
padding: 18px 0 13px; font-family: 'Open Sans', arial, tahoma; margin-bottom: 40px;">
    <a><h3 style="margin-left: 10px;">Back</h3></a>
</div>

<mat-card *ngIf="post as post; else loading" style="width: 90%; margin-top: 5%; overflow: hidden; display: block; min-width: 550px;">
    <div id="containesI" style="width: 54%; display: inline-block; margin: 10px 5px;">
        <div>
            <a>
                <img [src]="post.imageproduct" class="card-img-top" />
            </a>
        </div>
    </div>

    <div id="containesT" style="width: 44%; display: inline-block;padding-left: 5%; float: right;">
        <div>
            <mat-card-title>
                {{post.nombre}}
            </mat-card-title>
        </div>
        <div>
            <div>
                <h3 class="text-muted">$ {{post.valor}}</h3>
                <h4 class="text-muted">{{post.familiaProducto}}</h4>
                <!-- <h4 class="text-muted">{{post.cantidad}}</h4> -->
            </div>
        </div>

        <div style="margin-top: 20px;">

            <div style="width: 75px; border: 1px solid black; border-radius: 20px; display: inline-block;">
                <div style="width: 26px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;"
                    (click)="restarProd()">-</div>
                <div style="width: 20px; padding: 5px; text-align: center;  display: inline-block;">{{cantProd}}</div>
                <div style="width: 26px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;"
                    (click)="sumarProd()">+</div>
            </div>

            <button style="border-radius: 30px; margin-left: 5%;" class="card-inner header" mat-stroked-button
                (click)="agregarAlCarro()">Agregar a la bolsa</button>
        </div>

        <div style="display: block; margin-top: 15px;">
            <h4 class="text-muted" style="display: inline-block;">Disponibilidad: </h4>&nbsp;&nbsp;
            <div *ngIf="post.cantidad > 0" style="display: inline-block;">
                <h4 style="color: green;">Disponible</h4>
            </div>
            <div *ngIf="post.cantidad == 0" style="display: inline-block;">
                <h4 style="color: red">Agotado</h4>
            </div>
            <!-- <ng-template #noDisp style="display: inline-block;">
                <h4 style="color: red">Agotado</h4>
            </ng-template> -->
        </div>

        <div style="display: block; margin-top: 15px;">
            <h4 class="text-muted" style="display: inline-block;">Descripcion: </h4>&nbsp;&nbsp;
            <h4 style="display: inline-block;">{{post.descripcion}}</h4>
        </div>

    </div>
</mat-card>


<ng-template #loading>
    <div class="container-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>