import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaHistorialb'
})
export class FechaHistorialbPipe implements PipeTransform {

  transform(fecha: any) {
    var date = new Date(fecha.seconds*1000);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var minutes = date.getMinutes();
    var hours = date.getHours();
    var seconds = date.getSeconds();
    var myFormattedDate = day + "-" + (monthIndex + 1) + "-" + year + " " + hours + ":" + minutes + ":" + seconds;
    return myFormattedDate;
    ;
  }

}
