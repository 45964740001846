import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-app',
  templateUrl: './container-app.component.html',
  styleUrls: ['./container-app.component.scss']
})
export class ContainerAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
