import { Component, Input, OnInit } from '@angular/core';
import { PostService } from '../post.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PostI } from 'src/app/shared/models/post.interface';
import * as jsPDF from 'jspdf'
import * as JsBarcode from 'jsbarcode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit {
  private image: any;
  @Input() dataCompleta: any;
  public newCodeMasControl;
  public destacado: boolean = false;
  public newCod:any;

  constructor(private postSvc: PostService) { }

  public newPostForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    cantidad: new FormControl('', Validators.required),
    valor: new FormControl('', Validators.required),
    sucursal: new FormControl('', Validators.required),
    familiaProducto: new FormControl('', Validators.required),
    imageproduct: new FormControl('', Validators.required),
    codigo: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    categoria: new FormControl('', Validators.required),
    codigoBarraManual: new FormControl('')
  });

  ngOnInit(): void {
    this.newCod = this.dataCompleta.newCode;
    if (this.digitoDeControl(this.newCod)) {
      this.newPostForm.patchValue({ codigo: this.newCodeMasControl });
    }
  }

  addNewPost(data) {
    data.vecesVendido = 0;
    let pasa = true;
    if(data.codigoBarraManual){
      var temp = this.dataCompleta.codigoList.filter(element => element == data.codigoBarraManual);
      if(temp.length == 0){
        if(this.digitoDeControl(data.codigoBarraManual)){
          data.codigo = this.newCodeMasControl;
        }
      } else{
        Swal.fire('codigo en uso');
        console.log("codigo existente");
        pasa = false;
      }
    }
    if(pasa){   
      delete data.codigoBarraManual;
      this.postSvc.preAddAndUpdatePost(data, this.image);
    }
    
  }

  generarBarcode() {
    JsBarcode('#barcode', this.newCod, {
      format: 'EAN13',
      displayValue: true,
    });
    let doc = new jsPDF('l','mm',[100,150]);
    const canvas = document.getElementById('barcode') as HTMLCanvasElement;
    const jpegUrl = canvas.toDataURL('image/jpeg');
    doc.addImage(jpegUrl, 'JPEG', 0, 0, 50, 30);
    doc.output('dataurlnewwindow');
  }

  handleImage(event: any): void {
    this.image = event.target.files[0];
  }

  digitoDeControl(num: number) {
    var digitoDeControl;
    var pares;
    var inpares;
    var inparMasPar;
    var decenaSUperior;
    // console.log('nuevo codigo=> ', num);
    pares = Number(num.toString().charAt(0)) + Number(num.toString().charAt(2)) + Number(num.toString().charAt(4)) + Number(num.toString().charAt(6)) + Number(num.toString().charAt(8)) + Number(num.toString().charAt(10));
    // console.log('suma pares=> ', pares);
    inpares = Number(num.toString().charAt(1)) + Number(num.toString().charAt(3)) + Number(num.toString().charAt(5)) + Number(num.toString().charAt(7)) + Number(num.toString().charAt(9)) + Number(num.toString().charAt(11));
    // console.log('suma inpares=> ', inpares);
    inpares = inpares * 3;
    // console.log('suma inpares*3=> ', inpares);
    inparMasPar = inpares + pares;
    // console.log('pares + inpares*3=> ', inparMasPar);
    if (inparMasPar % 10 != 0) {
      decenaSUperior = inparMasPar - (inparMasPar % 10) + 10;
      // console.log('decena superior=> ', decenaSUperior);
      digitoDeControl = decenaSUperior - inparMasPar
    } else {
      digitoDeControl = 0;
    }
    // console.log('digito de control=> ', digitoDeControl);
    this.newCodeMasControl = num.toString() + digitoDeControl.toString();
    // console.log('new Code + Control=> ', this.newCodeMasControl);
    return this.newCodeMasControl;
  }

}
