<!-- <div *ngFor="let prod of post">
    <div>{{prod.nombre}}</div>
</div> -->

<div style="width: 90%; height: 60px; font-size: 13px; padding: 18px 0 13px; margin-bottom: 40px; margin: auto;">
    <div style="display: inline-block; padding: 5px; margin: 10px;" routerLink="/home" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
            class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
        <small style="font-size: 14px;"><strong>Volver</strong></small>
    </div>
</div>
<div style="width: 100%; overflow: hidden; display: block; min-width: 750px;">
    <div style="width: 90%; max-width: 1100px; margin: auto;">
        <div style="width: 100%; display: block; overflow: hidden;margin-bottom: 30PX;">
            <h3
                style="display: block; width: 300px; margin: auto; text-align: center; border-bottom: 1px solid #1fb2a6; font-weight: bold;">
                REALIZANDO COMPRA</h3>
        </div>
        <div style="width: 45%; display: inline-block; margin: 1px solid gray">
            <div style="width: 100%; height: 60px; background-color: #f6f6f8; font-size: 13px;
        padding: 18px 0 13px; font-family: 'Open Sans', arial, tahoma; margin-bottom: 40px;">
                <mat-icon style="display: inline-block;">home</mat-icon>
                <h3 style="margin-left: 25px; display: inline-block;">Datos de envio</h3>
            </div>
            <div class="form-group">
                <label for="email">Email:</label><span style="color: red;">*</span>
                <input type="email" class="form-control" [(ngModel)]='email' id="email">
            </div>
            <div class="form-group" style="display: inline-block; width: 47%;">
                <label for="email">Nombre:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='nombre' id="nombre">
            </div>
            <div class="form-group" style="display: inline-block; width: 47%; margin-left: 25px;">
                <label for="email">Apellido:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='apellido' id="apellido">
            </div>
            <div class="form-group" style="display: inline-block; width: 47%;">
                <label for="email">Rut:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='rut' id="rut">
            </div>
            <div class="form-group" style="display: inline-block; width: 47%; margin-left: 25px;">
                <label for="email">Teléfono:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='telefono' id="telefono">
            </div>
            <div class="form-group">
                <label for="email">Región:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='region' id="region">
            </div>
            <div class="form-group">
                <label for="email">Comuna:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='comuna' id="comuna">
            </div>
            <div class="form-group">
                <label for="email">Calle:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='calle' id="calle">
            </div>
            <div class="form-group">
                <label for="email">Numero:</label><span style="color: red;">*</span>
                <input type="text" class="form-control" [(ngModel)]='numero' id="numero">
            </div>
            <div class="form-group">
                <label for="email">Dpto/Block/Piso:</label>
                <input type="text" class="form-control" [(ngModel)]='piso' id="piso">
            </div>
        </div>


        <div style="width: 45%; display: inline-block; float: right; min-width: 350px;">
            <div style="width: 100%; background-color: #f6f6f8; font-size: 13px;
        padding: 18px 0 13px; font-family: 'Open Sans', arial, tahoma; margin-bottom: 60px; overflow: hidden;">
                <mat-icon style="display: inline-block;padding-left: 20px;">done</mat-icon>
                <h3 style="margin-left: 25px; display: inline-block;">Resumen del Pedido</h3>
                <div style="display: block; width: 100%;">
                    <div style="display: block; width: 100%; text-align: center; border-bottom: 1px solid gray;">
                        <div style="display: inline-block; width: 25%;">PRODUCTO</div>
                        <div style="display: inline-block; width: 25%;">CANTIDAD</div>
                        <div style="display: inline-block; width: 25%;">TOTAL</div>
                        <div style="display: inline-block; width: 25%;">QUITAR</div>
                    </div>
                    <div *ngFor="let prod of posts" style="border-bottom: 1px solid #82a99c;">
                        <div class="card3" style=" border-bottom: 1px solid white;background-color: #f6f6f8;">
                            <img [src]="prod.imageproduct" style="margin: 5px; float: left; width: 20%;">
                            <div style="text-align: left; float: left; width: 75%;">
                                <div style="width: 33%; display:inline-block; text-align: center;">

                                    <div
                                        style="margin-left: 15px; width: 70px; border: 1px solid  #82a99c; border-radius: 20px; display: inline-block;">

                                        <!-- {{prod.cantidad}} -->

                                        <div (click)="restarProd(prod)"
                                            style="width: 24px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;">
                                            -</div>
                                        <div
                                            style="width: 20px; padding: 5px; text-align: center;  display: inline-block;">
                                            {{prod.cantidad}}
                                        </div>
                                        <div (click)="sumarProd(prod)"
                                            style="width: 24px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;">
                                            +</div>

                                    </div>
                                </div>
                                <div style="margin: 5px; display: inline-block; width: 33%; text-align: center;">
                                    <h4>$ {{prod.valor}}</h4>
                                </div>

                                <div style="margin: 5px; display: inline-block; width: 33%; text-align: center;">
                                    <h1 style="color: red; cursor: pointer;" (click)="quitarDeLaCompra(prod)">X</h1>
                                </div>
                            </div>
                        </div>
                        <div style="font-weight: 600; font-size: 13px;
                                    color: #222;
                                    line-height: 1.428;
                                    display: block;">
                            <h3 style="margin: 0; margin-left: 5px;">{{prod.nombre}}</h3>
                        </div>
                    </div>
                </div>
                <div class="card3" style="display: block; width: 100%;background-color: #f6f6f8;">
                    <div style="display: inline-block;">Total</div>
                    <div style="display: inline-block;float: right; margin-right: 15px;">$ {{totalValorCompra}}</div>
                </div>
                <div style="display: block; width: 100%;">
                    <a mat-stroked-button style="margin-left: 10px; text-decoration: none;"
                        (click)="realizarVenta()">Realizar pedido</a>
                </div>
            </div>

            <div style="width: 100%; display: block; overflow: hidden;">
                <blockquote class="blockquote">
                    <p class="mb-0">Por el momento solo contamos con pago por transferencia.</p>
                    <footer class="blockquote-footer">pronto incluiremos <cite title="Source Title">Webpay y
                            Transbank</cite></footer>
                </blockquote>
                <img style="width: 30%; margin: auto;" src="assets/img/webpay.png">
            </div>
        </div>
    </div>
</div>