<div class="new-post-container" *ngIf="factura.message === 'Factura'">
    <form [formGroup]="newPostForm" (ngSubmit)="addNewFactura(newPostForm.value)">

        <mat-form-field>
            <mat-label>Numero de factura</mat-label>
            <input matInput placeholder="Numero de factura" formControlName="numFactura">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Valor Total</mat-label>
            <input matInput placeholder="valor Total" formControlName="valorTotal">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Detalle</mat-label>
            <input matInput placeholder="Cantidad: Producto, Cantidad: Producto" formControlName="productos">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="fecha">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Save" [mat-dialog-close]="true">
        </div>
    </form>
</div>

<!-- Genera el pdf Facturas-->

<div class="new-post-container" *ngIf="factura.message === 'pdf'">
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort id="dataFact">

        <ng-container matColumnDef="numFactura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Factura/Boleta </th>
            <td mat-cell *matCellDef="let element"> {{element.numFactura}} </td>
        </ng-container>
        <mat-form-field>
            <mat-select placeholder="Sucursal" multiple formControlName="sucursal">
                <mat-option value="Victoria">Victoria</mat-option>
                <mat-option value="Traiguen">Traiguen</mat-option>
            </mat-select>
        </mat-form-field>  
        <ng-container matColumnDef="valorTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Total </th>
            <td mat-cell *matCellDef="let element"> {{element.valorTotal}} </td>
        </ng-container>
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha | fechaHistorialb}} </td>
        </ng-container>
        <ng-container matColumnDef="productos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Detalle </th>
            <td mat-cell *matCellDef="let element"> {{element.productos}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    
    <button class="oculto-impresion" (click)="imprimir()">Imprimir</button>

</div>