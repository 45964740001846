import { Injectable } from '@angular/core';
import { UserI } from '../models/user.interface';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { User } from 'firebase';
import { FileI } from '../models/file.interface';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData$: Observable<firebase.User>;
  public filePath: string;
  public sucursalActiva: string;

  constructor(private afAuth: AngularFireAuth, private storegae: AngularFireStorage, private route: Router) { //, private userFire: User
    this.userData$ = afAuth.authState;
  }

  loginByEmail(user: UserI) {
    const { email, password } = user;
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.afAuth.signOut().then(rest => {
      this.route.navigate(['/login']);
    }).catch( 
      err => console.log('Error', err)
    );
  }

  preSaveUserProfile(user: UserI, image:FileI): void{
    if (image){
      this.uploadImage(user, image);
    } else {
      this.saveUserProfile(user);
    }

  };

  private uploadImage(user: UserI, image: FileI): void {
    this.filePath = `image/${image.name}`;
    const fileRef = this.storegae.ref(this.filePath);
    const task = this.storegae.upload(this.filePath, image);
    task.snapshotChanges().pipe(finalize(() => {
      fileRef.getDownloadURL().subscribe(urlImage => {
        user.photoURL = urlImage;
        this.saveUserProfile(user);
      });
    })
    ).subscribe();
  }

  private saveUserProfile(user: UserI) {

    
    // this.userFire.updateProfile({
    //   displayName: "Jane Q. User",
    //   photoURL: "https://example.com/jane-q-user/profile.jpg"
    //   }).then(()=> console.log('User update')).catch(err => console.log('Error', err));

    // this.afAuth.updateCurrentUser(user)
    // .then(()=> console.log('User update')).catch(err => console.log('Error', err));
  }

}
