<div style="width: 100%; display: inline-block; min-width: 350px;">
    <div style="width: 100%; background-color: #f6f6f8; font-size: 13px;
padding: 18px 0 13px; font-family: 'Open Sans', arial, tahoma; margin-bottom: 60px; overflow: hidden;">
        <div style="display: block; width: 100%;">
            <div style="display: block; width: 100%; text-align: center; border-bottom: 1px solid gray;">
                <div style="display: inline-block; width: 25%;">PRODUCTO</div>
                <div style="display: inline-block; width: 25%;">CANTIDAD</div>
                <div style="display: inline-block; width: 25%;">QUITAR</div>
            </div>
            <div *ngFor="let prod of posts" style="border-bottom: 1px solid #82a99c;">
                <div class="card3" style=" border-bottom: 1px solid white;background-color: #f6f6f8;">
                    <img [src]="prod.imageproduct" style="margin: 5px; float: left; width: 20%;">
                    <div style="text-align: left; float: left; width: 75%;">
                        <div style="width: 33%; display:inline-block; text-align: center;">

                            <div
                                style="margin-left: 15px; width: 70px; border: 1px solid  #82a99c; border-radius: 20px; display: inline-block;">

                                <!-- {{prod.cantidad}} -->

                                <div (click)="restarProd(prod)"
                                    style="width: 24px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;">
                                    -</div>
                                <div
                                    style="width: 20px; padding: 5px; text-align: center;  display: inline-block;">
                                    {{prod.cantidad}}
                                </div>
                                <div (click)="sumarProd(prod)"
                                    style="width: 24px; padding: 5px; text-align: center; display: inline-block; cursor: pointer;">
                                    +</div>

                            </div>
                        </div>

                        <div style="margin: 5px; display: inline-block; width: 33%; text-align: center;">
                            <h1 style="color: red; cursor: pointer;" (click)="quitarDeLaCompra(prod)">X</h1>
                        </div>
                    </div>
                </div>
                <div style="font-weight: 600; font-size: 13px;
                            color: #222;
                            line-height: 1.428;
                            display: block;">
                    <h3 style="margin: 0; margin-left: 5px;">{{prod.nombre}}</h3>
                </div>
            </div>
        </div>

        <div style="display: block; width: 100%;">
            <a mat-stroked-button style="margin-left: 10px; text-decoration: none;" [mat-dialog-close] = "true"
                (click)="realizarTraspaso()">Realizar traspaso</a>
        </div>
    </div>
</div>