import { Component, Input, OnInit } from '@angular/core';
import { PostI } from 'src/app/shared/models/post.interface';
import { AdminComponent } from '../../admin/admin.component';
import { PostService } from '../post.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { Voucher } from 'src/app/shared/models/voucher';
import { MatDialog } from '@angular/material/dialog';
import { Modal2Component } from 'src/app/shared/components/modal2/modal2.component';
import { DatosEnvio } from 'src/app/shared/models/datosEnvio';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-carro-venta',
  templateUrl: './carro-venta.component.html',
  styleUrls: ['./carro-venta.component.scss']
})
export class CarroVentaComponent implements OnInit {

  // @Input() post: PostI[];
  public posts: PostI[] = [];
  public listVendidosFinal: PostI[] = [];
  public listaSinStock: PostI[] = [];
  public cantidadCompra: number;
  public totalValorCompra: number = 0;
  public email: string = '';
  public nombre: string = '';
  public apellido: string = '';
  public rut: string = '';
  public telefono: string = '';
  public region: string = '';
  public comuna: string = '';
  public calle: string = '';
  public numero: string = '';
  public piso: string = '';
  public productos: PostI[] = [];
  public prodz: any;
  public numeroPedido: number;
  numBoucher: Voucher;
  sucursalElecta: string;
  // url='https://www.almauaeropuertoaraucania.cl/contactform/';
  // , private http: HttpClient

  constructor(private postSvc: PostService, public dialog: MatDialog, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.posts = this.postSvc.getListaCompras();
    this.cantidadCompra = this.postSvc.getCantCompra();
    this.totalValorCompra = this.postSvc.getToValCompra();
    // console.log(this.posts);
    if (!this.postSvc.getListaCompras()) {
      this.router.navigate(['home']);

    }
    this.sucursalElecta = this.postSvc.getSucursalElecta();
    this.postSvc.getAllPosts(this.sucursalElecta).subscribe(productos => {
      this.productos = productos;
    });
  }

  realizarVenta() {
    if (this.totalValorCompra > 0) {
      if (this.email && this.nombre && this.apellido && this.rut && this.telefono && this.region && this.comuna && this.calle && this.numero) {
        this.spinner.show();
        // if (true) {
        this.listVendidosFinal = [];
        this.listaSinStock = [];
        this.posts.forEach((letVendido, idx) => {


          let vendido = $.extend({}, letVendido);

          const id: any = vendido.id;
          // this.postSvc.getOnePost(id).subscribe(letProdict => {

          this.prodz = this.productos.filter(prod => prod.id === vendido.id);
          let product = $.extend({}, this.prodz);
          if ((Number(product[0].cantidad) - Number(vendido.cantidad)) >= 0) {
            product[0].cantidad = Number(product[0].cantidad) - Number(vendido.cantidad);
            this.postSvc.editPostById(product[0]).then(rest => {
              this.listVendidosFinal.push(vendido);
              if (idx === (this.posts.length - 1)) {
                this.enviarPedido();
              }
            });
          } else {
            this.listaSinStock.push(vendido);
          }

          // });



        });
      } else Swal.fire('Debe completar Los campos obligatorios');
    } else Swal.fire('Debe agregar productos a la bolsa');

  }

  enviarPedido() {
    if (this.listVendidosFinal.length > 0) {
      if (this.listaSinStock.length > 0) {
        this.listaSinStock.forEach(producto => {
          this.totalValorCompra = this.totalValorCompra - producto.cantidad * producto.valor;
        });
        // enviar this.listVendidosFinal + this.totalValorCompra 
        //Swal() algunos productos estan sin stock, el pedido final fue el siguiente this.listVendidosFinal
      } else {
        this.sucursalElecta = this.postSvc.getSucursalElecta();
        this.postSvc.getUltimoVoucher(this.sucursalElecta).pipe(take(1)).subscribe(val => {
          this.numBoucher = $.extend({}, val[0]);
          if (this.numBoucher) {
            this.numBoucher.numBoucher = this.numBoucher.numBoucher + 1;
          } else this.numBoucher.numBoucher = 1;

          const envioObj = {
            email: this.email,
            nombre: this.nombre + ' ' + this.apellido,
            rut: this.rut,
            telefono: this.telefono,
            direccion: this.calle + ' ' + this.numero + ' ' + this.piso + ' ' + this.comuna + ' ' + this.region
          }

          const pedidoObj = {
            numero: this.numBoucher.numBoucher,
            estado: 'pendiente',
            productos: this.listVendidosFinal,
            totalValorCompra: this.totalValorCompra,
            fecha: new Date(),
            datosDeEnvio: envioObj,
            sucursal: this.sucursalElecta
          };
          this.numBoucher.sucursal = this.sucursalElecta
          this.postSvc.setPedidos(pedidoObj).then(rest => {
            this.postSvc.setUltimoVoucher(this.numBoucher).then(res => {
              this.spinner.hide();
              this.popUpComprobante(this.numBoucher.numBoucher);
            });
          });
        })
      }
    } else {
      //Swal lo sentivos el actualizado del stock nos indica que estos productos ya no estan disponibles,
      //vaciar carro
    }
    // this.http.post(`${this.url}contactform.php`, JSON.stringify(this.listVendidosFinal));
  }

  popUpComprobante(num) {
    this.openDialog(num);
  }

  openDialog(num?: number): void {
    const config = {
      data: {
        message: 'Comprobante',
        content: num
      }
    };
    const dialogRef = this.dialog.open(Modal2Component, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result ${result}`);
    });
  }


  restarProd(prod) {
    this.posts.forEach(prodEnLista => {
      if (prodEnLista.codigo === prod.codigo && prodEnLista.sucursal === prod.sucursal) {
        if (prodEnLista.cantidad > 1) {
          prodEnLista.cantidad = prodEnLista.cantidad - 1;
          this.nuevoValorCompra();
        } else {
          this.quitarDeLaCompra(prod);
        }
      }
    });
  }

  sumarProd(prod) {
    this.productos.forEach(prodEnStock => {
      if (prod.codigo === prodEnStock.codigo && prod.sucursal === prodEnStock.sucursal) {
        if (prodEnStock.cantidad > prod.cantidad) {
          prod.cantidad++;
          this.nuevoValorCompra();
        } else Swal.fire('Producto sin mas stock');
      }
    })
  }

  quitarDeLaCompra(prod) {
    this.posts = this.posts.filter(prodEnLista => prodEnLista.codigo != prod.codigo);
    this.nuevoValorCompra();
  }

  nuevoValorCompra() {
    this.totalValorCompra = 0;
    this.cantidadCompra = 0;
    this.posts.forEach(prodVal => {
      this.totalValorCompra = this.totalValorCompra + prodVal.cantidad * prodVal.valor;
      this.cantidadCompra = this.cantidadCompra + prodVal.cantidad;
    });
    this.postSvc.setListaCompras(this.posts, this.cantidadCompra, this.totalValorCompra)
  }

}
