<div class="new-post-container" style="width: 850px;" *ngIf="ventas.message === 'Ventas'">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="width: 800px;" id="tablaData">

        <ng-container matColumnDef="numBoucher">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Boleta </th>
            <td mat-cell *matCellDef="let element"> {{element.numBoucher}} </td>
        </ng-container>
        <ng-container matColumnDef="cantProd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Productos </th>
            <td mat-cell *matCellDef="let element"> {{element.cantProd}} </td>
        </ng-container>
        <ng-container matColumnDef="valorTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor total </th>
            <td mat-cell *matCellDef="let element"> {{element.valorTotal}} </td>
        </ng-container>
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha | fechaHistorialb}} </td>
        </ng-container>
        <ng-container matColumnDef="tipoPago">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Pago </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.tipoPago}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <button class="oculto-impresion" (click)="imprimir()">Imprimir</button>

</div>

<div class="new-post-container" *ngIf="ventas.message === 'Comprobante'">
    <div
        style="width: 90%; margin: auto; overflow: hidden; border: 1px solid #1fb2a6; padding-left: 10px; margin-bottom: 20px">
        <tr>
            <td>N° de Pedido:</td>
            <td>&nbsp;&nbsp;{{this.ventas.content}}</td>
        </tr>
        <p style="margin-top: 8px; margin-bottom: -3px;">Datos de transferencia:</p>
        <div
            style="text-align:left; width: 92%; margin: auto; overflow: hidden; border: 1px solid #1fb2a6; padding: 10px; margin-bottom: 10px;">
            <table>
                <tr>
                    <td>INSTITUCION: </td>
                    <td>&nbsp;Banco estado</td>
                </tr>
                <tr>
                    <td>TIPO CUENTA: </td>
                    <td>&nbsp;Cuenta corriente</td>
                </tr>
                <tr>
                    <td>N° CUENTA: </td>
                    <td>&nbsp;62100038817</td>
                </tr>
                <tr>
                    <td>RUT: </td>
                    <td>&nbsp;17.990.882-4</td>
                </tr>
                <tr>
                    <td>NOMBRE: </td>
                    <td>&nbsp;Matilde Valenzuela Sanhueza</td>
                </tr>
                <tr>
                    <td>CORREO:</td>
                    <td>&nbsp;isabela.lagos452@gmail.com</td>
                </tr>
            </table>
        </div>
        <p style="text-align:left;"><span style="color: red;">*</span> Favor incluir en el mensaje el numero de pedido
        </p>
        <p style="text-align:left;"><span style="color: red;">*</span> El pedido se reserva durante 15min hasta su
            transferencia </p>
    </div>

    <button class="oculto-impresion" (click)="cerrarV()">Cerrar</button>
    <!-- <button class="oculto-impresion" (click)="comprobante()">Abrir comprobante</button> -->

</div>


<div class="new-post-container" *ngIf="ventas.message === 'DetallePedido'">
    <div style="width: 100%; height: 100%; display: block; overflow: hidden;">
        <blockquote class="blockquote text-center" style="margin-bottom: 20; margin-top: 1rem">
            <p class="mb-0">DETALLE PENDIENTES</p>
        </blockquote>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="text-align: center;">
            <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo</th>
                <td mat-cell *matCellDef="let element"> {{element.codigo}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion</th>
                <td mat-cell *matCellDef="let element"> {{element.descripcion}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="familiaProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Familia Producto </th>
                <td mat-cell *matCellDef="let element"> {{element.familiaProducto}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let element"> {{element.cantidad}}&nbsp;&nbsp; </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>

<div class="new-post-container" *ngIf="ventas.message === 'StockVajo'">
    <div style="width: 100%; height: 100%; display: block; overflow: hidden;">
        <blockquote class="blockquote text-center" style="margin-bottom: 20px; margin-top: 1rem">
            <p class="mb-0">PRODUCTOS CON BAJO STOCK</p>
        </blockquote>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="text-align: center;">
            <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo</th>
                <td mat-cell *matCellDef="let element"> {{element.codigo}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion</th>
                <td mat-cell *matCellDef="let element"> {{element.descripcion}} &nbsp;&nbsp;</td>
            </ng-container>
            <ng-container matColumnDef="familiaProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Familia Producto </th>
                <td mat-cell *matCellDef="let element"> {{element.familiaProducto}} &nbsp;&nbsp;</td>
            </ng-container>
            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let element"> {{element.cantidad}}&nbsp;&nbsp; </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>

<!-- <div class="new-post-container" *ngIf="ventas.message === 'DetalleEnvio'">
    <div style="width: 100%; height: 100%; display: block; overflow: hidden;">
        <blockquote class="blockquote text-center" style="margin-bottom: 20px; margin-top: 1rem">
            <p class="mb-0">DETALLES DE ENVIO</p>
        </blockquote>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="text-align: center;">
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                <td mat-cell *matCellDef="let element"> {{element.email}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="rut">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rut</th>
                <td mat-cell *matCellDef="let element"> {{element.rut}} &nbsp;&nbsp;</td>
            </ng-container>
            <ng-container matColumnDef="telefono">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
                <td mat-cell *matCellDef="let element"> {{element.telefono}} &nbsp;&nbsp;</td>
            </ng-container>
            <ng-container matColumnDef="direccion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Direccion </th>
                <td mat-cell *matCellDef="let element"> {{element.direccion}}&nbsp;&nbsp; </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div> -->

<div class="new-post-container" *ngIf="ventas.message === 'DetalleEnvio'">
    <div
        style="width: 100%; margin: auto; overflow: hidden; border: 1px solid #1fb2a6;  margin-bottom: 20px;padding: 20px;">
        <blockquote class="blockquote text-center" style="margin-bottom: 20px; margin-top: 1rem">
            <p class="mb-0">Datos de envio</p>
        </blockquote>

        <table>
            <tr>
                <td>Email: </td>
                <td>&nbsp;{{ventas.content.email}}</td>
            </tr>
            <tr>
                <td>Nombre: </td>
                <td>&nbsp;{{ventas.content.nombre}}</td>
            </tr>
            <tr>
                <td>Rut: </td>
                <td>&nbsp;{{ventas.content.rut}}</td>
            </tr>
            <tr>
                <td>Telefono: </td>
                <td>&nbsp;{{ventas.content.telefono}}</td>
            </tr>
            <tr>
                <td>Direccion: </td>
                <td>&nbsp;{{ventas.content.direccion}}</td>
            </tr>
        </table>

    </div>

</div>

<div class="new-post-container" style="width: 850px;" *ngIf="ventas.message === 'prodTraspaso'">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="width: 800px;" id="tablaData">

        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion </th>
            <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>
        <ng-container matColumnDef="familiaProducto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> familiaProducto </th>
            <td mat-cell *matCellDef="let element"> {{element.familiaProducto}} </td>
        </ng-container>
        <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.cantidad}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button class="oculto-impresion" (click)="imprimir()">Imprimir</button>

</div>

<div class="new-post-container" style="width: 850px;" *ngIf="ventas.message === 'inventario'">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort style="width: 800px;" id="tablaData">

        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion </th>
            <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>
        <ng-container matColumnDef="familiaProducto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> familiaProducto </th>
            <td mat-cell *matCellDef="let element"> {{element.familiaProducto}} </td>
        </ng-container>
        <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.cantidad}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button class="oculto-impresion" (click)="imprimir()">Imprimir</button>

</div>