// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDDHgCvZC8VyfdaFAZS_vgS1mN_jM3Vjdo",
    authDomain: "isabellaaccesorios-d6969.firebaseapp.com",
    databaseURL: "https://isabellaaccesorios-d6969.firebaseio.com",
    projectId: "isabellaaccesorios-d6969",
    storageBucket: "isabellaaccesorios-d6969.appspot.com",
    messagingSenderId: "520056676709",
    appId: "1:520056676709:web:588f26fd11ba8c15a4c18c",
    measurementId: "G-CZHLHVTDMF"
  },
  pagoAPI: 'https://us-central1-isabellaaccesorios-d6969.cloudfunctions.net/app/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
