<div class="new-post-container">
    <form [formGroup]="editPostForm" (ngSubmit)="editPost(editPostForm.value)">
        <div class="container-input-file">
            <label>
                <mat-label><strong>Codigo: </strong></mat-label>
                <mat-label>{{post.codigo}}</mat-label>
            </label>
        </div>
        <mat-form-field>
            <mat-label>Familia del Producto</mat-label>
            <input matInput placeholder="Familia del Producto" formControlName="familiaProducto">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="nombre">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Descripcion</mat-label>
            <input matInput placeholder="Nombre" formControlName="descripcion">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input matInput placeholder="Cantidad" formControlName="cantidad">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Valor</mat-label>
            <input matInput placeholder="Valor" formControlName="valor">
        </mat-form-field>
        <!-- <mat-form-field>
            <mat-select placeholder="Sucursal" formControlName="sucursal">
                <mat-option value="Victoria">Victoria</mat-option>
                <mat-option value="Traiguen">Traiguen</mat-option>
            </mat-select>
        </mat-form-field> -->
        <mat-form-field>
            <mat-select placeholder="Categoria" multiple formControlName="categoria">
                <mat-option value="accesorio">accesorio</mat-option>
                <mat-option value="belleza">belleza</mat-option>
                <mat-option value="destacado">destacado</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="container-input-file">
            <label class="custom-file">
                <input type="file" formControlName="imageproduct" (change)="handleImage($event)">
                <mat-icon>cloud_upload</mat-icon><span>CAMBIAR IMAGEN</span>
            </label>
        </div>

        <div (click)="generarBarcode()" style="cursor: pointer; display: inline-block;">
            <ngx-barcode6 [bc-format]="'EAN13'" [bc-value]="codigoMostrar" [bc-display-value]="true" ></ngx-barcode6>
        </div>
        
        <canvas id="barcode" *ngIf="codigoMostrar" style="display: none;"></canvas>
        <mat-form-field>
            <mat-label>Codigo Manual</mat-label>
            <input matInput maxlength="12" placeholder="CodigoBarraManual" formControlName="codigoBarraManual">
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Save" [mat-dialog-close]="true">
        </div>
    </form>
</div>