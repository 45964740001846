import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostService } from '../post.service';
import { PostI } from '../../../shared/models/post.interface';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-details-post',
  templateUrl: './details-post.component.html',
  styleUrls: ['./details-post.component.scss']
})
export class DetailsPostComponent implements OnInit {
  // public post$: Observable<PostI>
  @Input() post: any;
  public posts: PostI[];
  public total: any;
  public date: Date;
  public cantProd: number = 1;
  // @Output() cantCarro: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute, private postSvc: PostService) { }

  ngOnInit(): void {
    this.date = new Date();
    // const idPost = this.route.snapshot.params.id;
    // this.post$ = this.postSvc.getOnePost(idPost);
    if (this.post.message) {
      this.posts = this.post.content;
    } else {
      this.posts = this.post.content;
      this.total = this.post.total;
    }

    // console.log(this.post);
    // console.log(this.posts);
    // const idPost = this.post.content.id;
  }

  // comprar() {
  //   this.posts
  //   Swal.fire('Es necesario integrar método de pago');
  //   // PENDIENTE: bostrap form para los datos de la direccion y
  // }

  imprimir() {

    const DATA = document.getElementById('datBouch');
    const doc = new jsPDF('p','pt','A6');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = 200;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const doc2 = new jsPDF('p','pt',[pdfWidth+15,pdfHeight+15]);
      doc2.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc2;
    }).then((docResult) => {
      docResult.output('dataurlnewwindow');
    });
    
  }

  restarProd(){
    this.cantProd -= this.cantProd;
  }

  sumarProd(){
    this.cantProd += this.cantProd;
  }

  agregarAlCarro(){

  }

}
