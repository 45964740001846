import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NewPostComponent } from './components/posts/new-post/new-post.component';
import { NewPostModule } from './components/posts/new-post/new-post.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component';

/* Firebase , StorageBucket*/
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from "@angular/fire/storage";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainerAppComponent } from './components/pages/container-app/container-app.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { EditPostComponent } from './components/posts/edit-post/edit-post.component';
import { EditPostModule } from './components/posts/edit-post/edit-post.module';
import { DetailsPostComponent } from './components/posts/details-post/details-post.component';

// Import ngx-barcode module
import { NgxBarcode6Module } from 'ngx-barcode6';
import { CarroVentaComponent } from './components/posts/carro-venta/carro-venta.component';
import { DetailsPost1Component } from './components/posts/details-post1/details-post1.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { BannerTopComponent } from './shared/components/banner-top/banner-top.component';
import { BannerComponent } from './components/banner/banner.component';
import { HttpClientModule } from '@angular/common/http';
import { IngresoFacturaComponent } from './components/posts/ingreso-factura/ingreso-factura.component';
import { Modal2Component } from './shared/components/modal2/modal2.component';
import { VentaPdfComponent } from './components/posts/venta-pdf/venta-pdf.component';
import { VentaPdfModule } from './components/posts/venta-pdf/venta-pdf.module';
import { FechaHistorialbPipe } from './shared/pipe/fecha-historialb.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarroCambioComponent } from './components/posts/carro-cambio/carro-cambio.component';

@NgModule({
  declarations: [
    AppComponent,
    NewPostComponent,
    ToolbarComponent,
    ContainerAppComponent,
    ModalComponent,
    EditPostComponent,
    DetailsPostComponent,
    CarroVentaComponent,
    DetailsPost1Component,
    HeaderComponent,
    BannerTopComponent,
    BannerComponent,
    IngresoFacturaComponent,
    Modal2Component,
    VentaPdfComponent,
    FechaHistorialbPipe,
    CarroCambioComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    NewPostModule,
    MaterialModule,
    ReactiveFormsModule,
    EditPostModule,
    NgxBarcode6Module,
    FormsModule,
    HttpClientModule,
    VentaPdfModule,
    NgxSpinnerModule
  ],
  entryComponents:[ModalComponent],
  providers: [
    {provide: BUCKET, useValue:'gs://isabellaaccesorios-d6969.appspot.com'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
