import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import { PostService } from '../post.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { sucursal } from 'src/app/shared/models/sucursal';
declare var $: any;

@Component({
  selector: 'app-ingreso-factura',
  templateUrl: './ingreso-factura.component.html',
  styleUrls: ['./ingreso-factura.component.scss']
})
export class IngresoFacturaComponent implements OnInit {
  @Input() factura: any;
  displayedColumns: string[] = ['numFactura','sucursal', 'valorTotal','fecha', 'productos'];
  dataSource = new MatTableDataSource();
  sucursalActiva: string;
  sucursalActiva1: sucursal;

  constructor(private authSvc: AuthService, private postSvc: PostService, private postSvs: PostService) { }

  public newPostForm = new FormGroup({
    numFactura: new FormControl('', Validators.required),
    valorTotal: new FormControl('', Validators.required),
    productos: new FormControl('', Validators.required),
    fecha: new FormControl('', Validators.required),
    sucursal: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.authSvc.userData$.subscribe(user => {
      this.postSvs.getSucursalBD(user.email).subscribe(sucursal => {
        this.sucursalActiva1 = $.extend({}, sucursal);
        this.sucursalActiva = this.sucursalActiva1.sucursal;
      });
    });
    if (this.factura.message === 'pdf') {
      this.dataSource.data = this.factura.content;
      console.log(this.dataSource.data);
      console.log(this.factura.content);
    } else {

    }

  }

  addNewFactura(data) {
    // PENDIENTE: formatear la fecha
    if (data.fecha, data.valorTotal, data.numFactura, data.productos) {
      data.sucursal = this.sucursalActiva;
      this.postSvc.setFacturas(data);
    } else {
      Swal.fire('Debe completar todos los campos');
    }
  }

  imprimir() {

    if (this.factura.content.length > 0) {
      // const DATA = document.getElementById('tablaData');
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont("courier");
      doc.setFontType("italic");
      doc.setFontSize(11);

      var data = 'Codigo \tCantidad     familia producto\tnombre\n';
      data = data + '------------------------------------------------------------------------------------\n';
      var max = 0;
      this.factura.content.forEach(element => {
        if(element.familiaProducto.length>max){
          max = element.familiaProducto.length;
        }
      });
      var pagina = 29;
      this.factura.content.forEach((element, idx) => {
        var aux = element.familiaProducto;
        var aux1 = 0;
        var aux2 = 0;
        aux1 = max-element.familiaProducto.length;
        while(aux2<=aux1){
          aux = aux +' ';
          aux2++;
        }
        data = data + element.codigo + '      ' + element.cantidad + '         ' + aux + '  ' + element.nombre + '\n';
        data = data + '-----------------------------------------------------------------------------------\n';
        if(idx===pagina-1){
          doc.text(20, 20, data);
          data='';
        }
        if(idx===pagina){
          pagina = pagina + 30;
          doc.addPage('a4', 'p');
        }
      });
      
      doc.output('dataurlnewwindow');

    }
        
    // const DATA = document.getElementById('dataFact');
    // const doc = new jsPDF('p','pt','a4');
    // const options = {
    //   background: 'white',
    //   scale: 3
    // };

    // html2canvas(DATA, options).then((canvas) => {

    //   const img = canvas.toDataURL('image/PNG');

    //   // Add image Canvas to PDF
    //   const bufferX = 15;
    //   const bufferY = 15;
    //   const imgProps = (doc as any).getImageProperties(img);
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //   return doc;
    // }).then((docResult) => {
    //   docResult.output('dataurlnewwindow');
    // });
    
  }

}
