<router-outlet></router-outlet>

<!-- Footer -->
<footer class="page-footer font-small blue pt-4">

    <div class="footer-copyright text-center py-3">
       <!--<img class="logoPrincipal" style="width: 100px;" src="assets/img/logo1.jpeg">&nbsp;&nbsp;
        <img class="logoPrincipal" style="width: 150px;" src="assets/img/logo2.jpeg">&nbsp;&nbsp;-->
    </div>
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
        © 2021 Copyright:
        <a routerLink="/login" routerLinkActive="active"
            style="text-decoration: none; color: black; cursor: text;">Novedades Isabella</a>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>