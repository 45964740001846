import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContainerAppComponent } from './components/pages/container-app/container-app.component';
import { CarroVentaComponent } from './components/posts/carro-venta/carro-venta.component';
import { DetailsPost1Component } from './components/posts/details-post1/details-post1.component';
const routes: Routes = [
  {
    path: '',
    component: ContainerAppComponent,
    children: [
      { path: 'home', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule) },
      { path: 'post/:id', component: DetailsPost1Component },
      { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule) },
      { path: 'contacto', loadChildren: () => import('./components/pages/contacto/contacto.module').then(m => m.ContactoModule) },
      { path: 'carro', component: CarroVentaComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ]
  },

  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'login', loadChildren: () => import('./components/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'caja', loadChildren: () => import('./components/posts/caja/caja.module').then(m => m.CajaModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }