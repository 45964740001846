import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PostI } from 'src/app/shared/models/post.interface';
import { TrackHttpError } from 'src/app/shared/models/trackHttpError';
import { Voucher } from 'src/app/shared/models/voucher';
import { PostService } from '../post.service';
import Swal from 'sweetalert2';
import { Modal2Component } from 'src/app/shared/components/modal2/modal2.component';
import { take } from 'rxjs/operators';
import { sucursal } from 'src/app/shared/models/sucursal';
import { AuthService } from 'src/app/shared/services/auth.service';
declare var $: any;

@Component({
  selector: 'app-carro-cambio',
  templateUrl: './carro-cambio.component.html',
  styleUrls: ['./carro-cambio.component.scss']
})
export class CarroCambioComponent implements OnInit {
  //@Input() post: any;
  public posts: PostI[] = [];
  public listTraspasadosFinal: PostI[] = [];
  public listaSinStock: PostI[] = [];
  public cantidadCompra: number;
  public totalValorCompra: number = 0;
  public email: string = '';
  public nombre: string = '';
  public apellido: string = '';
  public rut: string = '';
  public telefono: string = '';
  public region: string = '';
  public comuna: string = '';
  public calle: string = '';
  public numero: string = '';
  public piso: string = '';
  public productos: PostI[] = [];
  public productos34: PostI[] = [];
  public productos341: PostI[] = [];
  public prodz: any;
  public prodz2: any;
  public numeroPedido: number;
  numBoucher: Voucher;
  sucursalActiva: string;
  public venta = false;

  constructor(private postSvc: PostService, public dialog: MatDialog, private router: Router, private spinner: NgxSpinnerService, public authSvc: AuthService) { }

  ngOnInit(): void {
    this.posts = this.postSvc.getListaProdCambioSucursal();
    this.sucursalActiva = this.posts[0].sucursal;
    this.postSvc.getAllPosts(this.sucursalActiva).subscribe(productos => {
      this.productos341 = productos;
      this.productos = $.extend({}, this.productos341);
      let aux = Object.entries(this.productos);
      this.productos = [];
      aux.forEach((exterior, idx) => {
        this.productos[idx] = exterior[1]
      });
    });
  }

  realizarTraspaso() {
    if (this.posts && this.posts.length > 0) {
      this.spinner.show();
      this.listTraspasadosFinal = [];
      this.listaSinStock = [];
      this.posts.forEach((letTraspasado, idx) => {
        let traspasado = $.extend({}, letTraspasado);
        this.prodz = this.productos.filter(prod => prod.id === traspasado.id); //ñklhzgijñb knñ
        let product = $.extend({}, this.prodz);
        if ((Number(product[0].cantidad) - Number(traspasado.cantidad)) >= 0) {
          product[0].cantidad = Number(product[0].cantidad) - Number(traspasado.cantidad);
          this.postSvc.editPostById(product[0]).then(rest => {
            this.listTraspasadosFinal.push(traspasado);
            if (idx === (this.posts.length - 1)) {
              this.enviarPedido(this.listTraspasadosFinal);
            }
          });
        } else {
          this.listaSinStock.push(traspasado);
          this.spinner.hide();
        }

      });
    } else Swal.fire('Debe agregar productos al carro');

  }

  enviarPedido(ListaACambiar) {
    if (this.sucursalActiva == 'Victoria') {
      this.postSvc.getAllPosts("Traiguen").pipe(take(1)).subscribe(productos => {
        this.productos34 = $.extend({}, productos);
        let aux = Object.entries(this.productos34);
        this.productos34 = [];
        aux.forEach((exterior, idx) => {
          this.productos34[idx] = exterior[1]
        });
        for (let i = 0; i < ListaACambiar.length; i++) {
          let vendido: PostI = $.extend({}, ListaACambiar[i]);
          if (this.productos34 && this.productos34.length > 0) {
            this.prodz2 = this.productos34.filter(prod => prod.codigo === vendido.codigo);
            if (this.prodz2.length > 0) {
              let product = $.extend({}, this.prodz2);
              product[0].cantidad = Number(product[0].cantidad) + Number(vendido.cantidad);
              this.postSvc.editPostById(product[0]);
            } else {
              vendido.id = '';
              vendido.sucursal = 'Traiguen';
              this.postSvc.editPostById(vendido);
            }
          } else {
            vendido.id = '';
            vendido.sucursal = 'Traiguen';
            this.postSvc.editPostById(vendido);
          }
        }
        this.openDialogTraspaso(ListaACambiar);
        this.posts = [];
        this.listTraspasadosFinal = [];
        this.postSvc.setListaProdCambioSucursal(this.posts);
        this.spinner.hide();
      });
    } else {
      this.postSvc.getAllPosts('Victoria').pipe(take(1)).subscribe(productos => {
        this.productos34 = $.extend({}, productos);
        let aux = Object.entries(this.productos34);
        this.productos34 = [];
        aux.forEach((exterior, idx) => {
          this.productos34[idx] = exterior[1]
        });
        for (let i = 0; i < ListaACambiar.length; i++) {
          let vendido: PostI = $.extend({}, ListaACambiar[i]);
          if (this.productos34 && this.productos34.length > 0) {
            this.prodz2 = this.productos34.filter(prod => prod.codigo === vendido.codigo);
            if (this.prodz2.length > 0) {
              let product = $.extend({}, this.prodz2);
              product[0].cantidad = Number(product[0].cantidad) + Number(vendido.cantidad);
              this.postSvc.editPostById(product[0]);
            } else {
              vendido.id = '';
              vendido.sucursal = 'Victoria';
              this.postSvc.editPostById(vendido);
            }
          } else {
            vendido.id = '';
            vendido.sucursal = 'Victoria';
            this.postSvc.editPostById(vendido);
          }
        }
        this.openDialogTraspaso(ListaACambiar);
        this.posts = [];
        this.listTraspasadosFinal = [];
        this.postSvc.setListaProdCambioSucursal(this.posts);
        this.spinner.hide();
      });
    }
  }


  restarProd(prod) {
    this.posts.forEach(prodEnLista => {
      if (prodEnLista.id === prod.id) {
        if (prodEnLista.cantidad > 1) {
          prodEnLista.cantidad = prodEnLista.cantidad - 1;
        }
      }
    });
  }

  sumarProd(prod) {
    this.productos.forEach(prodEnStock => {
      if (prod.id === prodEnStock.id) {
        if (prodEnStock.cantidad > prod.cantidad) {
          prod.cantidad++;
        } else Swal.fire('Producto sin mas stock');
      }
    })
  }

  quitarDeLaCompra(prod) {
    this.posts = this.posts.filter(prodEnLista => prodEnLista.codigo != prod.codigo);
    this.postSvc.setListaProdCambioSucursal(this.posts);
    //PENDIENTE: cerrar la ventana cuando no queden productos
  }

  openDialogTraspaso(Traspasos?: PostI[]): void {
    const config = {
      data: {
        message: 'prodTraspaso',
        content: Traspasos,
      }
    };
    const dialogRef = this.dialog.open(Modal2Component, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result ${result}`);
    });
  }

}
