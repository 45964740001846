<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <img src="assets/img/logo.jpeg" width="220" class="d-inline-block align-top" alt="">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" style="font-weight: bold; cursor: pointer;" (click)="ahome()">HOME</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" style="font-weight: bold" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    CATEGORIAS                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a (click)="irA('dest')"  style="cursor: pointer;" class="dropdown-item">Novedades</a>
                    <div class="dropdown-divider"></div>
                    <a (click)="irA('acc')" style="cursor: pointer;" class="dropdown-item">Accesorios</a>
                    <div class="dropdown-divider"></div>
                    <a (click)="irA('bel')" style="cursor: pointer;" class="dropdown-item">Belleza</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="font-weight: bold" routerLink="/about">QUIENES SOMOS</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" style="font-weight: bold" routerLink="/contacto">CONTACTO</a>
            </li>
        </ul>
    </div>
</nav>




<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light"> -->

<!-- <a class="navbar-brand" routerLink="/" style="float: left;">
        <img class="logoPrincipal" src="assets/img/logo.jpeg" style="width: 5%;">&nbsp;&nbsp;
        Novedades <span class="text-danger"> Isabella</span>
    </a> -->

<!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button> -->

<!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <a class="navbar-brand" href="#">
            <img src="assets/img/logo.jpeg" width="220" class="d-inline-block align-top" alt="">
        </a>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item ">
                <a class="nav-link" (click)="ahome()" style="cursor: pointer;">Home</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" routerLink="/about">QUIENES SOMOS</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" routerLink="/contacto">CONTACTO</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Categorias
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Belleza</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Accesorios</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">otros</a>
                </div>
            </li>
        </ul>
    </div>
</nav> -->