<!-- <mat-toolbar color="primary">
    <a routerLink="/">{{appName}}</a>
    <span class="fill-remaining-space"></span>

    <a routerLink="/admin" routerLinkActive="active" *ngIf="authSvc.userData$ | async">
        <button mat-button>Admin</button>
    </a>
    <a routerLink="/about" routerLinkActive="active">
        <button mat-button>About</button>
    </a>

    <a *ngIf="authSvc.userData$ | async; else showLogin" (click)="onLogout()">
        <button mat-button>Logout</button>
    </a>

    <ng-template #showLogin>
        <a routerLink="/login" routerLinkActive="active">
            <button mat-button>Login</button>
        </a>
    </ng-template>

</mat-toolbar> -->

<header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark" style="text-align: right">
        <a class="navbar-brand" routerLink="/" style="float: left;">
            <!-- <img class="logoPrincipal" src="assets/img/logo.jpeg">&nbsp;&nbsp; -->
            Novedades <span class="text-danger"> Isabella</span>
        </a>

        <div style="width: 30%; float: right;overflow: hidden; display: inline-block;">
            <a routerLink="/">
                <span>HOME</span>
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a routerLink="/about">
                <span>QUIENES SOMOS</span>
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a routerLink="/contacto">
                <span>CONTACTO</span>
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- <a routerLink="/about" style="text-decoration: none;">
                <button style="margin-left: 5%; border: 0;" class="card-inner header" mat-stroked-button>QUIENES SOMOS</button>
            </a>
            <a routerLink="/contacto" style="text-decoration: none;">
                <button style="margin-left: 5%; border: 0;" class="card-inner header" mat-stroked-button>CONTACTO</button>
            </a> -->
        </div>

        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" routerLink="/">
                        Home <span class="sr-only">(current)</span>
                    </a>
                </li>
            </ul>
        </div> -->
    </nav>
</header>
<!-- <div style="width: 100%; text-align: center; display: block; overflow: hidden;">
    <div style="width: 30%; float: right;overflow: hidden; display: inline-block;">
        <a routerLink="/about" routerLinkActive="active">
            <button mat-button>QUIENES SOMOS</button>
        </a>
        <a routerLink="/contacto" routerLinkActive="active">
            <button mat-button>CONTACTO</button>
        </a>
    </div>
</div> -->