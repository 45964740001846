import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { PostI } from 'src/app/shared/models/post.interface';
import Swal from 'sweetalert2';
import { PostService } from '../post.service';

@Component({
  selector: 'app-details-post1',
  templateUrl: './details-post1.component.html',
  styleUrls: ['./details-post1.component.scss']
})
export class DetailsPost1Component implements OnInit {
  public post: PostI;
  public total: any;
  public date: Date;
  public cantProd: number = 0;
  // @Output() cantCarro: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute, private postSvc: PostService) { }

  ngOnInit(): void {
    const idPost = this.route.snapshot.params.id;
    this.postSvc.getOnePost(idPost).subscribe(product => {
      this.post = product;
      // this.post.cantidad--;
    });

    console.log(this.post);
  }



  restarProd() {
    if (this.cantProd > 0) {
      this.cantProd = this.cantProd - 1;
      this.post.cantidad ++;
    }
  }

  sumarProd() {
    if (this.post.cantidad > 0) {
      this.cantProd = this.cantProd + 1;
      this.post.cantidad --;
    }
  }

  agregarAlCarro() {
    console.log('aca');
  }
}
