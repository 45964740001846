import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PostI } from '../../../shared/models/post.interface';
import { PostService } from './../post.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as jsPDF from 'jspdf'
import * as JsBarcode from 'jsbarcode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {
  private image: any;
  private imageOriginal: any;
  public codigoMostrar: any;
  public doc:any;
  public newCodeMasControl;
  public post:PostI;
  @Input() dataCompleta: any;

  constructor(private postSvc: PostService) { }

  public editPostForm = new FormGroup({
    id: new FormControl('', Validators.required),
    nombre: new FormControl('', Validators.required),
    cantidad: new FormControl('', Validators.required),
    valor: new FormControl('', Validators.required),
    // sucursal: new FormControl('', Validators.required),
    familiaProducto: new FormControl('', Validators.required),
    imageproduct: new FormControl('', Validators.required),
    codigo: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    categoria: new FormControl('', Validators.required),
    codigoBarraManual: new FormControl('')
  });

  ngOnInit(): void {
    this.post = this.dataCompleta.content;
    this.image = this.post.imageproduct;
    this.imageOriginal = this.post.imageproduct;
    this.codigoGenerador();
    this.initValuesFormPost();
  }

  editPost(post) {
    let pasa = true;
    if (this.image === this.imageOriginal) {
      post.imageproduct = this.imageOriginal;
      if(post.codigoBarraManual){
        var temp = this.dataCompleta.codigoList.filter(element => element == post.codigoBarraManual);
        if(temp.length == 0){
          if(this.digitoDeControl(post.codigoBarraManual)){
            post.codigo = this.newCodeMasControl;
          }
        } else{
          Swal.fire('codigo en uso');
          console.log("codigo existente");
          pasa = false;
        }
      }
      if(pasa){ 
        delete post.codigoBarraManual;
        this.postSvc.editPostById(post);
      }
    } else {
      if(post.codigoBarraManual){
        var temp = this.dataCompleta.codigoList.filter(element => element == post.codigoBarraManual);
        if(temp[0].toString().trim() == null && temp[0].toString().trim() == ""){
          if(this.digitoDeControl(post.codigoBarraManual)){
            post.codigo = this.newCodeMasControl;
          }
        } else{
          Swal.fire('codigo en uso');
          console.log("codigo existente");
          pasa = false;
        }
      }
      if(pasa){ 
        delete post.codigoBarraManual;
        this.postSvc.editPostById(post, this.image);
      }
    }

  }

  handleImage(event: any): void {
    this.image = event.target.files[0];
  }

  private initValuesFormPost(): void {
    this.editPostForm.patchValue({
      id: this.post.id,
      nombre: this.post.nombre,
      cantidad: this.post.cantidad,
      valor: this.post.valor,
      sucursal: this.post.sucursal, 
      familiaProducto: this.post.familiaProducto,
      codigo: this.post.codigo,
      descripcion: this.post.descripcion,
      categoria: this.post.categoria
    })
  }

  generarBarcode() {
    JsBarcode('#barcode', this.codigoMostrar, {
      format: 'EAN13',
      displayValue: true,
    });
    let doc = new jsPDF('l','mm',[100,150]);
    const canvas = document.getElementById('barcode') as HTMLCanvasElement;
    const jpegUrl = canvas.toDataURL('image/jpeg');
    doc.addImage(jpegUrl, 'JPEG', 0, 0, 50, 30);
    doc.output('dataurlnewwindow');
  }

  codigoGenerador(){
    this.codigoMostrar = this.post.codigo;
    this.codigoMostrar = Number(this.codigoMostrar.substring(0, this.codigoMostrar.length - 1));
  }

  digitoDeControl(num: number) {
    var digitoDeControl;
    var pares;
    var inpares;
    var inparMasPar;
    var decenaSUperior;
    // console.log('nuevo codigo=> ', num);
    pares = Number(num.toString().charAt(0)) + Number(num.toString().charAt(2)) + Number(num.toString().charAt(4)) + Number(num.toString().charAt(6)) + Number(num.toString().charAt(8)) + Number(num.toString().charAt(10));
    // console.log('suma pares=> ', pares);
    inpares = Number(num.toString().charAt(1)) + Number(num.toString().charAt(3)) + Number(num.toString().charAt(5)) + Number(num.toString().charAt(7)) + Number(num.toString().charAt(9)) + Number(num.toString().charAt(11));
    // console.log('suma inpares=> ', inpares);
    inpares = inpares * 3;
    // console.log('suma inpares*3=> ', inpares);
    inparMasPar = inpares + pares;
    // console.log('pares + inpares*3=> ', inparMasPar);
    if (inparMasPar % 10 != 0) {
      decenaSUperior = inparMasPar - (inparMasPar % 10) + 10;
      // console.log('decena superior=> ', decenaSUperior);
      digitoDeControl = decenaSUperior - inparMasPar
    } else {
      digitoDeControl = 0;
    }
    // console.log('digito de control=> ', digitoDeControl);
    this.newCodeMasControl = num.toString() + digitoDeControl.toString();
    // console.log('new Code + Control=> ', this.newCodeMasControl);
    return this.newCodeMasControl;
  }

}
