import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { PostI } from '../../shared/models/post.interface';
import { FileI } from '../../shared/models/file.interface';
import { AngularFireStorage } from '@angular/fire/storage';
import { Venta } from 'src/app/shared/models/venta.interface';
import { Voucher } from 'src/app/shared/models/voucher';
import { Factura } from 'src/app/shared/models/factura';
import { Pedido } from 'src/app/shared/models/pedido';
import { Contacto } from 'src/app/shared/models/contacto';
import { sucursal } from 'src/app/shared/models/sucursal';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private postsCollection: AngularFirestoreCollection<PostI>
  private ventaCollection: AngularFirestoreCollection<Venta>
  private voucherCollection: AngularFirestoreCollection<Voucher>
  private facturaCollection: AngularFirestoreCollection<Factura>
  private pedidosCollection: AngularFirestoreCollection<Pedido>
  private contactoCollection: AngularFirestoreCollection<Contacto>
  private sucursalCollection: AngularFirestoreCollection<sucursal>
  private filePath: any;
  private downloadURL: Observable<string>;
  private listaCompras: PostI[];
  private cantidadCompra: number;
  private totalValorCompra: number;
  private listaProductos: Observable<PostI[]>;
  private listaVentas: Observable<Venta[]>;
  private ultimoVoucher: Observable<Voucher[]>;
  private listaFacturas: Observable<Factura[]>;
  private listaPedidos: Observable<Pedido[]>;
  private listaContacto: Observable<Contacto[]>;
  private sucursalElecta: string;
  private listaCambioSucursal: PostI[];

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.postsCollection = afs.collection<PostI>('posts');
    this.ventaCollection = afs.collection<Venta>('ventas');
    this.voucherCollection = afs.collection<Voucher>('voucher');
    this.facturaCollection = afs.collection<Factura>('facturas');
    this.pedidosCollection = afs.collection<Pedido>('pedidos');
    this.contactoCollection = afs.collection<Contacto>('contacto');
    this.sucursalCollection = afs.collection<sucursal>('sucursal');
  }

  public getAllPosts(sucursalElecta: string): Observable<PostI[]> {
    this.listaProductos = this.afs.collection<PostI>('posts', ref => ref.where('sucursal', '==', sucursalElecta)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as PostI;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.listaProductos;
  }

  public getOnePost(id: PostI): Observable<PostI> {
    return this.afs.doc<PostI>(`posts/${id}`).valueChanges();
  }

  public deletePostById(post: PostI) {
    return this.postsCollection.doc(post.id).delete();
  }

  public editPostById(post: PostI, newImage?: FileI): Promise<any> {
    if (newImage) {
      this.uploadImage(post, newImage);
    } else {
      return this.savePost(post);
      
    }
  }

  public preAddAndUpdatePost(post: PostI, image: FileI) {
    if(image){
      this.uploadImage(post, image);
    }else{
      this.savePost(post);
    }
  }

  private savePost(post: PostI) {
    const postObj = {
      nombre: post.nombre ? post.nombre: "",
      cantidad: post.cantidad,
      valor: post.valor,
      familiaProducto: post.familiaProducto ? post.familiaProducto: '',
      imageproduct: this.downloadURL ? this.downloadURL: '',
      fileRef: this.filePath ? this.filePath: '',
      codigo: post.codigo,
      descripcion: post.descripcion ? post.descripcion: '',
      categoria: post.categoria ? post.categoria: '',
      vecesVendido: post.vecesVendido ? post.vecesVendido: 0,
      sucursal: post.sucursal ? post.sucursal: 'Victoria'
    };

    if (post.id) {
      return this.postsCollection.doc(post.id).update(postObj);
    } else {
      return this.postsCollection.add(postObj);
    }
  }

  private uploadImage(post: PostI, image: FileI) {
    this.filePath = `images/${image.name}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, image);
    task.snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(urlImage => {
            this.downloadURL = urlImage;
            this.savePost(post);
          })
        })
      ).subscribe();
  }

  setListaCompras(listaCompras: PostI[], cantidadCompra: number, totalValorCompra: number) {
    this.listaCompras = listaCompras;
    this.cantidadCompra = cantidadCompra;
    this.totalValorCompra = totalValorCompra;
  }

  getListaCompras() {
    return this.listaCompras
  }

  getCantCompra() {
    return this.cantidadCompra;
  }

  getToValCompra() {
    return this.totalValorCompra;
  }

  setListaProductos(listaProductos) {
    this.listaProductos = listaProductos;
    // console.log(this.listaProductos);
  }

  getListaProductos() {
    return this.listaProductos;
  }

  public getAllSales(sucursalActiva): Observable<Venta[]> {
    this.listaVentas = this.afs.collection<Venta>('ventas', ref => ref.where('sucursal', '==', sucursalActiva)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Venta;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.listaVentas;
  }

  public saveSale(venta: Venta) {
    const postObj = {
      numBoucher: venta.numBoucher,
      cantProd: venta.cantProd,
      fecha: venta.fecha,
      productos: venta.productos,
      tipoPago: venta.tipoPago,
      valorTotal: venta.valorTotal,
      sucursal: venta.sucursal
    };

    return this.ventaCollection.add(postObj);
  }

  public getUltimoVoucher(sucursalActiva: string) {
    this.ultimoVoucher = this.afs.collection<Voucher>('voucher', ref => ref.where('sucursal', '==', sucursalActiva)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Voucher;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.ultimoVoucher;
  }

  public setUltimoVoucher(numVoucher: Voucher) {

    const postObj = {
      numBoucher: numVoucher.numBoucher,
      sucursal: numVoucher.sucursal
    };
    return this.voucherCollection.doc(numVoucher.id).update(postObj);
  }

  public getAllFacturas(sucursalActiva): Observable<Factura[]> {
    this.listaFacturas = this.afs.collection<Factura>('facturas', ref => ref.where('sucursal', '==', sucursalActiva)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Factura;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.listaFacturas;
  }

  public setFacturas(factura: Factura) {

    const postObj = {
      numFactura: factura.numFactura,
      valorTotal: factura.valorTotal,
      productos: factura.productos,
      fecha: factura.fecha,
      sucursal: factura.sucursal
    };
    if (factura.id) {
      return this.facturaCollection.doc(factura.id).update(postObj);
    } else {
      return this.facturaCollection.add(postObj);
    }
  }

  public getVentaPorFecha(inicio: Date, fin: Date, sucursalActiva: string): Observable<Venta[]> {
    this.listaVentas = this.afs.collection<Venta>('ventas', ref => ref.where('sucursal', '==', sucursalActiva).where('fecha', '>', inicio)
      .where('fecha', '<', fin)).snapshotChanges().pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Venta;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    return this.listaVentas;
  }

  public getFacturaPorFecha(inicio: Date, fin: Date, sucursalActiva: string): Observable<Factura[]> {
    this.listaFacturas = this.afs.collection<Factura>('facturas', ref => ref.where('sucursal', '==', sucursalActiva).where('fecha', '>', inicio)
      .where('fecha', '<', fin)).snapshotChanges().pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Factura;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    return this.listaFacturas;
  }

  public setPedidos(pedido: Pedido) {

    const postObj = {
      numero: pedido.numero,
      estado: pedido.estado,
      totalValorCompra: pedido.totalValorCompra,
      productos: pedido.productos,
      fecha: pedido.fecha,
      datosDeEnvio: pedido.datosDeEnvio,
      sucursal: pedido.sucursal
    };
    return this.pedidosCollection.add(postObj);
  }

  public getPedido(sucursalActiva: string): Observable<Pedido[]> {
    this.listaPedidos = this.afs.collection<Pedido>('pedidos', ref => ref.where('sucursal', '==', sucursalActiva)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Pedido;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.listaPedidos;
  }

  public setPedidosEstado(pedido: Pedido) {

    const postObj = {
      numero: pedido.numero,
      estado: pedido.estado,
      totalValorCompra: pedido.totalValorCompra,
      productos: pedido.productos,
      fecha: pedido.fecha,
      datosDeEnvio: pedido.datosDeEnvio,
      sucursal: pedido.sucursal
    };
    return this.pedidosCollection.doc(pedido.id).update(postObj);
  }

  public deletePendiente(pedido: Pedido) {
    return this.pedidosCollection.doc(pedido.id).delete();
  }

  public getAllContacto(sucursalActiva): Observable<Contacto[]> {
    this.listaContacto = this.afs.collection<Contacto>('contacto', ref => ref.where('sucursal', '==', sucursalActiva)).snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Contacto;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    return this.listaContacto;
  }

  public setContactoEstado(contacto: Contacto) {

    const postObj = {
      nombre: contacto.nombre,
      apellido: contacto.apellido,
      fecha: contacto.fecha,
      email: contacto.email,
      telefono: contacto.telefono,
      mensaje: contacto.mensaje,
      estado: contacto.estado,
      sucursal: contacto.sucursal
    };
    return this.contactoCollection.doc(contacto.id).update(postObj);
  }

  public setContacto(contacto: Contacto) {

    const postObj = {
      nombre: contacto.nombre,
      apellido: contacto.apellido,
      fecha: contacto.fecha,
      email: contacto.email,
      telefono: contacto.telefono,
      mensaje: contacto.mensaje,
      estado: contacto.estado,
      sucursal: contacto.sucursal
    };
    return this.contactoCollection.add(postObj);
  }

  public deleteContacto(contacto: Contacto) {
    return this.contactoCollection.doc(contacto.id).delete();
  }

  public getHoyVentas(inicio: Date, fin: Date, sucursalActiva: string): Observable<Venta[]> {
    this.listaVentas = this.afs.collection<Venta>('ventas', ref => ref.where('sucursal', '==', sucursalActiva)
    .where('fecha', '>=', inicio).where('fecha', '<=', fin)).snapshotChanges().pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Venta;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    return this.listaVentas;
  }

  public getSucursalBD(correo: string){
    return this.afs.doc<sucursal>(`sucursal/${correo}`).valueChanges();
  }
  public setSucursalBD(correo: string, sucursal1: string){
    var sucursal = {sucursal: sucursal1}
    this.sucursalCollection.doc(correo).update(sucursal);
  }

  public getSucursalElecta(){
    return this.sucursalElecta;
  }

  setSucursalElecta(sucursal){
    this.sucursalElecta = sucursal;
  }

  
  getListaProdCambioSucursal(){
    return this.listaCambioSucursal;
  }

  setListaProdCambioSucursal(listaCambioSucursal){
    this.listaCambioSucursal = listaCambioSucursal;
  }

  public getAllAllPosts(): Observable<PostI[]> {
    this.listaProductos = this.afs.collection<PostI>('posts').snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as PostI;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );

    return this.listaProductos;
  }
  

}
