import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-banner-top',
  templateUrl: './banner-top.component.html',
  styleUrls: ['./banner-top.component.scss']
})
export class BannerTopComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ahome() {
    if (this.router.url === "/home") {
      window.location.reload();
    } else this.router.navigate(['home']);
  }

  irA(lugar) {
    if (this.router.navigate(['home'])) {
      if (lugar === 'dest') {
        setTimeout(() => { $('html, body').animate({
          scrollTop: $("#novedades").offset().top
        }, 300); }, 500);
      }

      if (lugar === 'acc') {
        setTimeout(() => { $('html, body').animate({
          scrollTop: $("#accesorios").offset().top
        }, 1000);}, 500);
      }

      if (lugar === 'bel') {
        setTimeout(() => { $('html, body').animate({
          scrollTop: $("#belleza").offset().top
        }, 1000);}, 500);
      }
    }
  }

}
